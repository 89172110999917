/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, createContext, useContext, useState } from "react";
import { useRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";
import { useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";

import { useAlerts } from "./alerts";

import { GraphQLInput } from "hooks/datalist";
import { useAuth } from "contexts/auth";
import { useDialog } from "contexts/dialog";
import { Status } from "utils/status";
import { defaultCookieOptions, parseCookieBooleanValue } from "utils/cookie";
import { RedirectTypes } from "utils/project";
import {
  deleteProject,
  generateProject,
  copyProject as copyProjectMutation,
} from "graphql/mutations";
import { CustomError } from "utils/error";

interface ProjectsContextValue {
  keyword: string | null;
  setKeyword: (k: string | null) => void;
  isProjectsFilterSaved: boolean;
  setIsProjectsFilterSaved: (visible: boolean) => void;
  statusFilter: Status[];
  setStatusFilter: (k: Status[]) => void;
  copy: (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => Promise<void>;
  create: (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => Promise<void>;
  remove: (id: string) => Promise<void>;
}

interface ProjectsContextProps {
  children: ReactNode;
  isForManager?: boolean;
}

const ProjectsContext = createContext<ProjectsContextValue>({
  keyword: null,
  setKeyword: () => null,
  isProjectsFilterSaved: false,
  setIsProjectsFilterSaved: () => null,
  statusFilter: [],
  setStatusFilter: () => null,
  copy: () => Promise.resolve(),
  create: () => Promise.resolve(),
  remove: () => Promise.resolve(),
});

export const ProjectsProvider = ({ children }: ProjectsContextProps) => {
  const cookies = parseCookies();
  const isProjectsFilterApplied = parseCookieBooleanValue(
    cookies.isProjectsFilterSaved
  );

  const router = useRouter();
  const { currentGroup, user } = useAuth();
  const [keyword, setKeyword] = useState<string | null>(
    isProjectsFilterApplied && cookies.projectsKeyword
      ? cookies.projectsKeyword
      : null
  );
  const [statusFilter, setStatusFilter] = useState<Status[]>([]);
  const queryClient = useQueryClient();

  // PAX-360 Gantt検索条件はCookieに保存する、そのトグル
  const [isProjectsFilterSaved, setIsProjectsFilterSaved] = useState<boolean>(
    isProjectsFilterApplied
  );

  if (isProjectsFilterSaved) {
    setCookie(null, "projectsKeyword", keyword ?? "", defaultCookieOptions);
  }
  // PAX-360 Cookieには文字列しか格納できないため,文字列"true", "false"を利用
  setCookie(
    null,
    "isProjectsFilterSaved",
    isProjectsFilterSaved ? "true" : "false",
    defaultCookieOptions
  );

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  const copyProject = async (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => {
    try {
      addAlert({ severity: "info", message: "作成中" });
      const res = await API.graphql({
        query: copyProjectMutation,
        variables: {
          input: {
            ...input,
            groupId: currentGroup?.id,
            userId: user?.attributes.sub,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const newProject = res.data.copyProject;
      if (!newProject) return;
      let url = `/projects/${(newProject as any).id}`;
      if (redirectType) {
        url += `?page=${redirectType}&modal=true`;
      }
      router.push(url);
      queryClient.invalidateQueries({
        queryKey: ["projectsByGroupIdForManager"],
      });
      addAlert({ severity: "success", message: "作成されました" });
    } catch (err) {
      const customError = new CustomError(err, "create");
      addAlert({ severity: "error", message: customError.message });
    }
  };

  const createProject = async (
    input: GraphQLInput,
    redirectType: RedirectTypes.schedules
  ) => {
    try {
      addAlert({ severity: "info", message: "作成中" });
      const res = await API.graphql({
        query: generateProject,
        variables: {
          input: {
            ...input,
            groupId: currentGroup?.id,
            userId: user?.attributes.sub,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const newProject = res.data.generateProject;
      if (!newProject) return;
      let url = `/projects/${(newProject as any).id}`;
      if (redirectType) {
        url += `?page=${redirectType}&modal=true`;
      }
      router.push(url);
      queryClient.invalidateQueries({
        queryKey: ["projectsByGroupIdForManager"],
      });
      addAlert({ severity: "success", message: "作成されました" });
    } catch (err) {
      const customError = new CustomError(err, "create");
      addAlert({ severity: "error", message: customError.message });
    }
  };

  const removeProject = async (id: string) => {
    open({
      title: "案件を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        try {
          await API.graphql({
            query: deleteProject,
            variables: {
              input: {
                id,
              },
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          queryClient.invalidateQueries({
            queryKey: ["projectsByGroupIdForManager"],
          });
          addAlert({ severity: "success", message: "削除されました" });
        } catch (err) {
          const customError = new CustomError(err, "delete");
          addAlert({ severity: "error", message: customError.message });
        }
      },
    });
  };

  return (
    <ProjectsContext.Provider
      value={{
        keyword,
        setKeyword,
        isProjectsFilterSaved,
        setIsProjectsFilterSaved,
        statusFilter,
        setStatusFilter,
        copy: copyProject,
        create: createProject,
        remove: removeProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const projectsContext = useContext(ProjectsContext);

  if (projectsContext === undefined) {
    throw new Error("useProjects must be within ProjectsProvider");
  }

  return projectsContext;
};
